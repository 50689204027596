import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Copyright } from '../pages/payments/PlanDetails';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
    height: 250
  },
  footer: {
    padding: theme.spacing(6),
  },
}))

const FooterSection = () => {
  const classes = useStyles();


  return (

    <footer className={classes.footer} style={{ backgroundImage: "linear-gradient(-11deg, #0b0b0b 0%, #1f1f1f 100%)" }}>
      <div style={{ textAlign: "center" }}>
        <img src={require("../assets/Group 551.svg").default} alt="smitch logo" />
        <Copyright />
      </div>

      <div style={{ margin: "auto", justifyContent: "center", alignItems: "center", textAlign: "center", width: "100%", height: "80px", color: "white", paddingTop:"1rem" }}>

        <a href="http://www.mysmitch.com/privacy" target="_blank" style={{
          margin: "auto", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "12px", color: "#9aa7b3", textDecoration: "none"
        }}>
          Privacy Policy | &nbsp;
        </a>
        <a href="http://www.mysmitch.com/terms" target="_blank" style={{
          margin: "auto", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "12px", color: "#9aa7b3", textDecoration: "none"
        }}>
          Terms & Conditions
        </a>
      </div>
    </footer>


  )
}

export default FooterSection