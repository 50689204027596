import React from 'react';
import { Button, Container, CssBaseline, Grid, TextField , Box, DialogContentText,DialogTitle, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import { ToastContext } from "../../Components/ToastProvider";
import '../../styles/Payment.css';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { PlanContext } from '../../Context/PlanContext';
import * as Sentry from "@sentry/react";
import { SSO_BASE, RAZOR_PAY_KEY } from '../../Components/constant';
import FooterSection from '../../Components/PaymentFooter'
import PaymentHeader from '../../Components/PaymentHeader';







const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 250,
    height: 250
  },
  footer: {
    padding: theme.spacing(6),
  },
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});



const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);








// main component

const PaymentMonthly = (props: any) => {

  let history = useHistory();
  const classes = useStyles();
  const { showToast } = React.useContext(ToastContext);
  const value = React.useContext(PlanContext)
  const queryParams = new URLSearchParams(window.location.search);
  const [data, setData] = React.useState({
    payment_token: queryParams.get("payment_token"),
  });

  const [open, setOpen] = React.useState(false);
  const [plan, setPlan] = React.useState("")
  const [pricing, setPricing] = React.useState("")
  const [discount, setDiscount] = React.useState(0.0)
  const [planId, setPlanId] = React.useState("")
  const [pricingYearly, setPricingYearly] = React.useState(0)
  const [discountYearly, setDiscountYearly] = React.useState(0)
  const [limitOfDeviceText, setLimitOfDeviceText] = React.useState("")
  const [limitOfDeviceTwo, setLimitOfDeviceTwo] = React.useState("")
  const [limitOfDeviceThree, setLimitOfDeviceThree] = React.useState("");
  const [ openModal , setOpenModal ] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
    getPlan()
  };
  const handleClose = () => {
    setOpen(false);
  }

  const getToken = () => {
    //@ts-ignore
    return localStorage.getItem("payment_token")
  }

  const closeModal = () => {
    setOpenModal(false);
    makePayment();
  }

  const openPayModal = (id: any) => {
    const _window = window as any
    var rzp1 = new _window.Razorpay(
      {

        "subscription_id": id,
        key: RAZOR_PAY_KEY,
        name: plan,
        image: 'https://d199xmsg3owom4.cloudfront.net/images/ss_logo.png', // need logo
        handler: function (response: any) {
          // console.log("id", response.razorpay_Monthly_id);
          // console.log(response.razorpay_order_id)
          // console.log(response.razorpay_signature)

          history.push("/ss/payment-success")

        },
        prefill: {
          name: "",
          contact: "",
          email: "",
        },
        notes: {
          address: ""
        },
        theme: {
          hide_topbar: false
        }
      }
    )
    rzp1.open();
    rzp1.on('Monthly.failed', function (response) {
      history.push("/ss/payment-failure")
      Sentry.captureException(response);

    }
    )
  }




  const getPlan = () => {
    let config_data = JSON.parse(localStorage.getItem("config_data") as string)
    let data = localStorage.getItem("seletedPlan")
    let pricing = JSON.parse(localStorage.getItem("pricing") as string)
    let data2 = pricing.monthly.price
    let description = localStorage.getItem("description")
    let descriptionTwo = localStorage.getItem("descriptionTwo")
    let descriptionThree = localStorage.getItem("descriptionThree")



    setPricing(data2)
    setPricingYearly(pricing.yearly.price)
    setDiscountYearly(pricing.yearly.discount)
    setPlanId(pricing.monthly.plan_id)
    //@ts-ignore
    setLimitOfDeviceText(description)
    //@ts-ignore
    setLimitOfDeviceTwo(descriptionTwo)
    //@ts-ignore
    setLimitOfDeviceThree(descriptionThree)
    let subscription = JSON.parse(localStorage.getItem("subscription") as string)
    //@ts-ignore
    setPlan(data)
    //@ts-ignore
    setPricing(data2)
    //@ts-ignore
    setDiscount(pricing.monthly.discount)

    if (subscription && config_data) {


      let subscriptionName = `Premium ${subscription.type[0]}${subscription.type.substring(1).toLowerCase()}`
      config_data.map((item) => {
        //@ts-ignore
        if (subscriptionName === item.title) {
          if (subscription.plan_name === "monthly") {
            data = subscriptionName
            data2 = item.pricing.yearly.price
            //@ts-ignore
            setPlan(data)
            //@ts-ignore
            setPricing(data2)
            setDiscount(item.pricing.monthly.discount)
            setLimitOfDeviceText(item.description[0])
            setLimitOfDeviceTwo(item.description[1])
            setLimitOfDeviceThree(item.description[2])


          }
          else {
            data = subscriptionName
            data2 = item.pricing.monthly.price
            //@ts-ignore
            setPlan(data)
            //@ts-ignore
            setPricing(data2)
            setDiscount(item.pricing.monthly.discount)
            setLimitOfDeviceText(item.description[0])
            setLimitOfDeviceTwo(item.description[1])
            setLimitOfDeviceThree(item.description[2])
          }
        }
      })
    }
  }


  const addPlan = (name, pricing, description, descriptiontwo, descriptionThree) => {
    localStorage.setItem("pricing", JSON.stringify(pricing))
    localStorage.setItem("seletedPlan", name)
    handleClose()
    let data2 = pricing.monthly.price

    setPricing(data2)
    setPlanId(pricing.monthly.plan_id)
    //@ts-ignore
    setPlan(name)
    //@ts-ignore
    setDiscount(pricing.monthly.discount)
    setPricingYearly(pricing.yearly.price)
    setDiscountYearly(pricing.yearly.discount)
    setLimitOfDeviceText(description)
    setLimitOfDeviceTwo(descriptiontwo)
    setLimitOfDeviceThree(descriptionThree)

  }



  React.useEffect(() => {
    document.title = "Payment Monthly | SMITCH";
    window.scrollTo(0, 0)
    let pricing = JSON.parse(localStorage.getItem("pricing") as string)
    getPlan()

    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);


  const makePayment = async () => {
    try {
      const result = await axios.post(SSO_BASE + `subscription/add?payment_token=${getToken()}`,
        {
          plan_id: planId,
          "service": "ss",
          "plan_name": "monthly"
        },
      )
      if (result.data) {
        openPayModal(result.data.data.id)
      }
    }
    catch (error) {
      //@ts-ignore
      showToast(error.response.data.message, "error")
      Sentry.captureException(error);


    }
  }




  return (
    <React.Fragment>
      <CssBaseline />
      <PaymentHeader />
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>

          <div style={{ backgroundColor: "#0f0d0d", color: "white", textAlign: "center", justifyContent: "center", alignItems: "baseline", paddingTop: "40px", }}>
            <br></br>
            <div className="change" onClick={handleClickOpen}><a>Change Plan</a></div>
          </div>
          <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
              <DialogContent className="modal__manage" style={{ width: "325px", color: "white", height: "640px", borderRadius: "17px" }}>
                {open ?
                  <div style={{ marginLeft: "17rem" }}>
                    <IconButton aria-label="close" onClick={handleClose} style={{ backgroundColor: "#22879d", color: "white", height: "25px", width: "25px" }}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                  : null}
                <p style={{ textAlign: "center" }}>Choose Your Plan</p>
                {value[0].map((plan, i) => (
                  <div style={{ margin: "auto" }}>
                    {(() => {
                      if (i <= 2) {
                        return (
                          <div className="plan__card_dialogthree" style={{ marginTop: "10px", paddingTop: "10px", marginBottom: "30px" }}>
                            <div style={{ textAlign: "center" }} key={i}>
                              <h4 className="dialog_box_name">{plan.title}</h4>
                              <h3 className="dialog_box_text">Rs {plan.pricing.monthly.price}/Month</h3>
                              <p className="dialogbox__para">{plan.description[1]}</p>
                            </div>
                            <div style={{ margin: "auto", justifyContent: "center", textAlign: "center", marginTop: "-10px" }}>
                              <Button className="continue" variant="contained" style={{ width: "145px", color: "white", height: "41px", borderRadius: "25px" }} onClick={() => addPlan(plan.title, plan.pricing, plan.description[0], plan.description[1], plan.description[2])} >Continue</Button>
                            </div>
                            <br></br>
                          </div>
                        )
                      }
                    })()}
                  </div>

                ))}
              </DialogContent>
            </Dialog>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <div className="plan" style={{ backgroundColor: "#0f0d0d", color: "white", paddingTop: "20px" }} >
          <h2 className="plan__name">{plan}</h2>
          <div style={{ textAlign: "center", paddingTop: "0px", alignItems: "baseline" }}>
            <img src={require("../../assets/mark.png").default} alt="4K logo" />
          </div>
          <h2 className="plan__money">Rs {pricing}/Month</h2>
          <p className="plan__text">{limitOfDeviceText}</p>
          <p className="plan__text">{limitOfDeviceTwo}</p>
          <p className="plan__text">{limitOfDeviceThree}</p>
          {/* <p className="tandc">T&C apply</p> */}
        </div>
      </Grid>


      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <div className="youcan" style={{ backgroundColor: "#0f0d0d", color: "white" }}>
            <h1 className="save">You can save <span style={{ color: "#5da755" }} >Rs {discountYearly}</span>
              <p className="save__text">You get 20% off when you subscribe to the yearly plan</p>
            </h1>
          </div>
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12} sm={12} md={12} style={{ backgroundColor: "#0f0d0d", color: "white", paddingTop: "20px" }}>
          <div className="plan"  >
            <h2 className="yearly__name_monthly">Choose to Bill Yearly</h2>
            <div style={{ textAlign: "center", paddingTop: "0px", alignItems: "baseline" }}>
              <img src={require("../../assets/mark.png").default} alt="4K logo" />
            </div>
            {/* @ts-ignore */}
            <h2 className="yearly__money">Rs {pricingYearly - discountYearly}/Year</h2>
            {/* @ts-ignore */}
            <h2 className="cross_text">
              <del>Rs {pricingYearly}/Year </del></h2>
            <div className="switch__button" style={{ margin: "auto", marginTop: "33px" }}>
              <Button style={{ color: "white", letterSpacing: "4.5px" }} onClick={() => history.push("/ss/payment-yearly")}>
                Switch to yearly
               </Button>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <div className="table" style={{ color: "white", paddingTop: "20px" }}>
            <div style={{ color: "white", paddingTop: "20px" }} >
              <h2 className="switch__text">{plan} - Monthly</h2>
            </div>
            <TableContainer style={{ color: "white", paddingTop: "20px" }}>
              <Table className={classes.table} aria-label="simple table" >
                <TableHead style={{ color: "white" }}>
                  <TableRow style={{ color: "white" }}>
                    <TableCell style={{ color: "white", alignItems: "revert" }} >Plan Name</TableCell>
                    {/* <TableCell style={{ color: "white" }}>Qty</TableCell> */}
                    <TableCell style={{ color: "white" }} align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={plan}>
                    <TableCell className="namecell" component="th" scope="row" style={{ color: "white" }} >
                      {plan}
                      <p>    Discount</p>
                    </TableCell>
                    {/* <TableCell style={{ color: "white" }}  > </TableCell> */}
                    <TableCell style={{ color: "white" }} align="right"  >{pricing}<p>{discount}</p></TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell style={{ color: "white", borderBottom: "none" }}><p>Total</p></TableCell>
                    {/* <TableCell style={{ color: "white", borderBottom: "none" }}  ></TableCell> */}
                    {/* @ts-ignore */}
                    <TableCell style={{ color: "white", borderBottom: "none" }} align="right"   > Rs: {pricing - discount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ margin: "auto", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <Button className="payment__button" style={{ color: "white", letterSpacing: "4.5px", width: "275px", borderRadius: "25px" }} onClick={() => setOpenModal(true)}>
                Proceed to payment
                </Button>
            </div>
            <br></br>
            <div>
              <p className="auto">You will be billed automatically every <br></br> month through our secure payment<br></br> gateway. You may choose to cancel<br></br> anytime.</p>
            </div>
          </div>
        </Grid>
      </Grid>


      <div style={{ backgroundColor: "black", height: "60px", paddingTop: "6px" }} onClick={() => history.push(`/ss/payment-yearly?payment_token=${data.payment_token}`)}>
        <p className="rs">Save Rs {discountYearly} by paying yearly</p>

      </div>
      <FooterSection />
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box m = {2} style={{fontSize: "18px", fontWeight: "bold"}}>
              Introducing UPI autopayment
            </Box>
            <Box m = {2} style={{fontSize: "18px"}}>
              You can now enable recurring payments for the subscription with UPI using PhonePe, Paytm or BHIM apps.
            </Box>
            <Box m = {2}>
            Note: in order to cancel the subscription, use the Smitch Secure app to cancel. If you cancel the autopayment via the UPI apps, the Smitch Premium plan will get cancelled with immediate effect, irrespective of whether the plan is still ongoing.  
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions style = {{ margin: "10px 25px"}}>
          <Button style = {{fontWeight: "bold", fontSize: "18px" , color : "rgba(0, 0, 0, 0.54)"}} onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={closeModal} autoFocus className = "continue" style = {{fontWeight: "bold", fontSize: "18px", color : "#FFF", padding : "11px !important"}}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default PaymentMonthly
