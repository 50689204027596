import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import * as Yup from "yup";
import { Formik } from "formik";
import {
    Box, Button, Container, Grid, TextField, Typography, makeStyles, Link,
} from "@material-ui/core";
import { ToastContext } from "../Components/ToastProvider";
import axios from "axios";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import PhoneAndroidTwoToneIcon from '@material-ui/icons/PhoneAndroidTwoTone';
import Copyright from "../Components/copyright";
import Header from '../Components/header';
import { GCLIENT_ID, API_URL, REDIRECT_URL, APPLE_C_ID, LIVE_CAM_BASE, SSO_BASE } from "../Components/constant";
import { FACEBOOK_APP_ID } from "../Components/constant";
import { DEFAULT_CLIENT_ID } from "../Components/constant";

import "../styles/signin.css";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(4),
    },
    input: {
        color: "#bdbdbd"
    },
    link: {
        textDecoration: "none"
    },
}));

const SignIn = () => {
    const classes = useStyles();
    const { showToast } = React.useContext(ToastContext);
    let history = useHistory();

    const [deviceid, setDeviceid] = useState([]);




    const [data, setData] = React.useState({});
    const [userId, setUserId] = React.useState("");
    const queryParams = new URLSearchParams(window.location.search);
    const [res, setRes] = useState({
        //@ts-ignore
        client_id: queryParams.get("client_id") || DEFAULT_CLIENT_ID,
        //@ts-ignore
        redirect_uri: encodeURIComponent(queryParams.get("redirect_uri")),
        //@ts-ignore
        response_type: queryParams.get("response_type"),
        state: queryParams.get("state"),
        service: queryParams.get("service") || "sso"

    });
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };



    let icon: any;
    if (passwordShown == true) {
        icon = <VisibilityIcon onClick={togglePasswordVisiblity} />;
    } else if (passwordShown == false) {
        icon = <VisibilityOffIcon onClick={togglePasswordVisiblity} />;
    }



    // facebok response
    const responseFacebook = async (response: any) => {
        try {
            setData(response);
            const { email, id } = response;
            const result = await axios.post(
                API_URL + "oauth/signin/social",
                {
                    email: email,
                    login_type: "facebook",
                    facebook_account_id: id,
                    "service": res.service,
                    "client_id": res.client_id
                },
                { headers: { encryption: false } }
            );
            // console.log("result facebook", result)
            localStorage.setItem(
                "access_token",
                result.data.data.access_token
            );
            setUserId(result.data.user_id);
            if (result && result != null) {
                //@ts-ignore
                showToast(result.data.message, "success");
                localStorage.setItem("service", result.data.data.service);
                if (res.service === "sso") {
                    const response = await axios.post(SSO_BASE + "oauth/sso/accountlink/smitch/status", {
                        "client_id": res.client_id
                    }, {
                        headers: { "Content-Type": "application/json", "access_token": result.data.data.access_token }
                    });
                    if (response && response !== null) {
                        console.log("response", response);
                        if (response.data.status === "success" && response.data.data && response.data.data.accountLinked === false) {
                            // return (
                            //     history.push({
                            //         pathname: "/emailVerification",
                            //         state: {
                            //             reg_email: email ? email : "",
                            //             login_type: "facebook",
                            //             search : `?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`
                            //         }
                            //     })
                            // )
                            return history.push(`/account-link?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                        }
                        else if (response.data.status === "success" && response.data.data && response.data.data.accountLinked === true) {
                            return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                        }
                        else {
                            showToast(response.data.message, "error");
                        }
                    }
                }
                else
                    return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
            }
        } catch (error) {
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);


        }
    };

    // google  response
    const responseGoogle = async (response: any) => {
        try {
            const profileObj = response?.profileObj;
            if (profileObj != null) {
                const { email, googleId, name } = profileObj;
                const result = await axios.post(
                    API_URL + "oauth/signin/social",
                    {
                        email: email,
                        login_type: "google",
                        google_account_id: googleId,
                        "service": res.service,
                        "client_id": res.client_id
                    },
                    { headers: { encryption: false } }
                );
                console.log("result", result);
                localStorage.setItem(
                    "access_token",
                    result.data.data.access_token
                );
                setUserId(result.data.user_id);
                if (result && result != null) {
                    //@ts-ignore
                    showToast(result.data.message, "success");
                    localStorage.setItem("service", result.data.data.service);
                    if (res.service === "sso") {
                        const response = await axios.post(SSO_BASE + "oauth/sso/accountlink/smitch/status", {
                            "client_id": res.client_id
                        }, {
                            headers: { "Content-Type": "application/json", "access_token": result.data.data.access_token }
                        });
                        if (response && response !== null) {
                            console.log("response", response);
                            if (response.data.status === "success" && response.data.data && response.data.data.accountLinked === false) {
                                // return (
                                //     history.push({
                                //         pathname: "/emailVerification",
                                //         state: {
                                //             reg_email: email ? email : "",
                                //             login_type: "google",
                                //             search : `?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`
                                //         }
                                //     })
                                // )
                                return history.push(`/account-link?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                            }
                            else if (response.data.status === "success" && response.data.data && response.data.data.accountLinked === true) {
                                return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                            }
                            else {
                                showToast(response.data.message, "error");
                            }
                        }
                    }
                    else
                        return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                }
            }
        } catch (error) {
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);

        }
    };

    // apple response

    const responseApple = async (response: any) => {
        try {
            const { client, user } = response;
            console.log(user);
            const result = await axios.post(API_URL + "oauth/signin/social", {
                login_type: "apple",
                apple_account_id: user,
                "service": res.service,
                "client_id": res.client_id
            },
                { headers: { encryption: false } }
            );
            console.log(result);
            localStorage.setItem(
                "access_token",
                result.data.data.access_token
            );
            setUserId(result.data.user_id);
            if (result && result != null) {
                //@ts-ignore
                showToast(result.data.message, "success");
                localStorage.setItem("service", result.data.data.service);
                if (res.service === "sso") {
                    const response = await axios.post(SSO_BASE + "oauth/sso/accountlink/smitch/status", {
                        "client_id": res.client_id
                    }, {
                        headers: { "Content-Type": "application/json", "access_token": result.data.data.access_token }
                    });
                    if (response && response !== null) {
                        console.log("response", response);
                        if (response.data.status === "success" && response.data.data && response.data.data.accountLinked === false) {
                            // return (
                            //     history.push({
                            //         pathname: "/emailVerification",
                            //         state: {
                            //             reg_email: user ? user : "",
                            //             login_type: "apple",
                            //             search : `?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`
                            //         }
                            //     })
                            // )
                            return history.push(`/account-link?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                        }
                        else if (response.data.status === "success" && response.data.data && response.data.data.accountLinked === true) {
                            return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                        }
                        else {
                            showToast(response.data.message, "error");
                        }
                    }
                }
                else
                    return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
            }
        } catch (error) {
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);

        }
    };

    React.useEffect(() => {
        document.title = "Smitch | Sign In";
    }, []);

    const mobileLoginHandle = () => {
        return history.push(`/mobile?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)

    }

    const handleNavigation = () => {
        return history.push(`/sso/reset?service=${res.service}`)
    }



    return (
        <>
            <Header />
            <br></br>
            <br />
            <br></br>
            <div style={{ textAlign: "left", paddingTop: "3rem" }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center"
                >
                    <Container maxWidth="sm">
                        <Formik
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email("Must be a valid email")
                                    .max(255)
                                    .required("Email is required"),
                                password: Yup.string()
                                    .max(255)
                                    .required("Password is required"),
                            })}

                            onSubmit={async (values: any) => {
                                try {


                                    if (res.redirect_uri != null && res.state != null) {
                                        const result = await axios.post(API_URL + "oauth/signin", {
                                            "service": res.service,
                                            "login_type": "email",
                                            "email": values.email.toLowerCase(),
                                            "password": values.password,
                                            "client_id": res.client_id

                                        }, {
                                            headers: { encryption: false },
                                        });

                                        if (result.data.user_id) {
                                            setUserId(result.data.user_id);

                                        }
                                        if (result.data && res.service) {
                                            //@ts-ignore
                                            showToast(result.data.message, "success");
                                            console.log("result ", result);
                                            localStorage.setItem("access_token", result.data.data.access_token);
                                            localStorage.setItem("service", result.data.data.service);
                                            if (res.service === "sso") {
                                                console.log("Testtt");
                                                const response = await axios.post(SSO_BASE + "oauth/sso/accountlink/smitch/status", {
                                                    "client_id": res.client_id
                                                }, {
                                                    headers: { "Content-Type": "application/json", "access_token": result.data.data.access_token }
                                                });
                                                if (response && response !== null) {
                                                    console.log("response", response);
                                                    if (response.data.status === "success" && response.data.data && response.data.data.accountLinked === false) {
                                                        return (
                                                            history.push({
                                                                pathname: "/emailVerification",
                                                                state: {
                                                                    reg_email: values && values.email ? values.email : "",
                                                                    login_type: "email",
                                                                    search: `?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`
                                                                }
                                                            })
                                                        )
                                                    }
                                                    else if (response.data.status === "success" && response.data.data && response.data.data.accountLinked === true) {
                                                        return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                                                    }
                                                    else {
                                                        showToast(response.data.message, "error");
                                                    }
                                                }
                                            }
                                            else
                                                return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                                        }

                                    }
                                    else {


                                        const result = await axios.post(LIVE_CAM_BASE + 'fer/login',
                                            {
                                                "email": values.email,
                                                "password": values.password,
                                                "login_type": "email"
                                            },
                                            { headers: { "encryption": false } })
                                        if (result && result.data != null) {
                                            showToast("Service is not available right now ", "warn");

                                            // console.log("result" , result.data)
                                            // setDeviceid(result.data.camera_device_ids)
                                            // localStorage.setItem("fer_access_token", result.data.data.fer_access_token);
                                            // localStorage.setItem("user" , JSON.stringify(result.data.data))
                                            // showToast("login successful ", "success");
                                            // history.push({
                                            //     pathname: 'ss/live',
                                            //     state: {
                                            //         camera_device_ids: result.data.data.camera_device_ids, 
                                            //     }
                                            // })
                                            // window.location.reload(true);
                                        }

                                    }
                                }
                                catch (error) {
                                    //@ts-ignore
                                    showToast(error.response.data.message, "error")
                                    console.log(error)
                                    Sentry.captureException(error);


                                }
                            }}



                        // onSubmit={async (values: any) => {
                        //     try {

                        //         // TO DO SSO_BASE once common login done
                        //         const result = await axios.post(API_URL + "oauth/signin", {      
                        //             "service": res.service,
                        //              "login_type": "email",
                        //               "email": values.email,
                        //                "password": values.password,
                        //                "client_id":res.client_id

                        //             }, {
                        //             headers: { encryption: false },
                        //         });


                        //         localStorage.setItem("access_token",result.data.data.access_token );
                        //         if(result.data.user_id){
                        //         setUserId(result.data.user_id);

                        //         }
                        //         if (result.data && res.service) {
                        //             //@ts-ignore
                        //             showToast(result.data.message, "success");
                        //             return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                        //         }
                        // if(!res.redirect_uri && !res.client_id)
                        // {
                        //     setDeviceid(result.data.camera_device_ids)
                        //     let { fer_access_token } = result.data.data;
                        //     localStorage.setItem("fer_access_token", fer_access_token);
                        //     localStorage.setItem("device_id", result.data.data.camera_device_ids);
                        //     showToast("login successful ", "success");
                        //     history.push("/ss/live")
                        // }

                        //     } catch (error) {
                        //         //@ts-ignore
                        //         showToast(error.response.data.message, "error")
                        //         //   console.log(Object.keys(error), error.message); 
                        //         console.log(error) // <---- 

                        //     }
                        // }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box mb={3}>
                                        <Typography variant="h3">Smitch </Typography>
                                        {!res.state ?
                                            <p > <a style={{ textDecoration: "none" }} href="http://www.mysmitch.com/"> We are launching our website soon..</a> </p> : <p>{decodeURIComponent(res.redirect_uri).includes('oauth-redirect.googleusercontent.com') ? <p >By signin in , you are authorizing Google to access your devices.</p> : null}</p>}




                                    </Box>
                                    {res.service != "sh" ?
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <FacebookLogin
                                                    appId={FACEBOOK_APP_ID}
                                                    fields="name,email,picture"
                                                    callback={responseFacebook}
                                                    render={(props: any) => (
                                                        <Button
                                                            className="socialButton"
                                                            onClick={props.onClick}
                                                        >
                                                            <img
                                                                className="btn-facebook "
                                                                src="static/images/FB@3x.png"
                                                                alt="fblogo"
                                                            />
                                                            <Typography
                                                                variant="button"
                                                                className="FB"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                Sign in With Facebook
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                ></FacebookLogin>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <GoogleLogin
                                                    clientId={GCLIENT_ID}
                                                    buttonText="Continue with Google"
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogle}
                                                    render={(props: any) => (
                                                        <Button
                                                            className="socialButton"
                                                            onClick={props.onClick}
                                                        >
                                                            <img
                                                                className="btn-logo"
                                                                src="static/images/Google@3x.png"
                                                                alt="GLogo"
                                                            />
                                                            <Typography
                                                                variant="button"
                                                                display="block"
                                                                className="FB"
                                                                gutterBottom
                                                            >
                                                                Sign in With Google
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <AppleLogin
                                                    clientId={APPLE_C_ID}
                                                    redirectURI={REDIRECT_URL}
                                                    responseType={"code id_token"}
                                                    responseMode={"form_post"}
                                                    //@ts-ignore
                                                    usePopup={true}
                                                    // responseType={"code"}
                                                    // responseMode={"query"}
                                                    callback={() => responseApple}
                                                    scope={"email "}
                                                    render={(props: any) => (
                                                        < Button
                                                            fullWidth
                                                            className="socialButton"
                                                            onClick={props.onClick}
                                                        >
                                                            <img
                                                                className="btn-apple"
                                                                src="static/images/apple.png"
                                                                alt="Logo"
                                                            />
                                                            <Typography
                                                                variant="button"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                &nbsp;  Sign in With Apple
                                                            </Typography>
                                                        </Button>
                                                    )}
                                                />
                                            </Grid>

                                            {/* <Grid item xs={12} md={12}>
                                            <Link onClick={mobileLoginHandle}>
                                                <Button className="socialButton"

                                                > &nbsp;<PhoneAndroidTwoToneIcon />&nbsp; &nbsp;Sign in with Mobile  </Button>
                                            </Link>

                                        </Grid> */}
                                        </Grid>
                                        : null
                                    }
                                    <br></br>
                                    {res.service != "sh" ?

                                        <div style={{ textAlign: "center" }}>OR</div> : null
                                    }

                                    <Box mt={2} mb={1}>
                                        <Typography
                                            align="center"
                                            color="textSecondary"
                                            variant="body1"
                                        >
                                            Sign in with your registered email address.
                                        </Typography>
                                    </Box>
                                    <TextField
                                        InputProps={{
                                            className: classes.input
                                        }}
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        label="Email Address"
                                        margin="normal"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="email"
                                        value={values.email}
                                        variant="outlined"
                                    />
                                    <TextField
                                        error={Boolean(touched.password && errors.password)}
                                        fullWidth
                                        helperText={touched.password && errors.password}
                                        label="Password"
                                        margin="normal"
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type={passwordShown ? "text" : "password"}
                                        value={values.password}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: icon
                                        }}
                                    />
                                    <Box my={2}>
                                        <Button
                                            color="primary"
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Sign in
                                        </Button>
                                    </Box>
                                    <Grid container>
                                        <Grid item xs style={{ display: "flex" }}>
                                            <Typography color="textSecondary" variant="body1">
                                                Don&apos;t remember password?{" "}
                                                <Button type="submit" color="primary"
                                                    onClick={() => history.push(`/sso/reset?service=${res.service}`)} >Reset Password
                                                </Button>

                                            </Typography>

                                        </Grid>
                                        {/* <Grid item>
                                            <Link href="/sso/signup" variant="body2">
                                                {"Don't have an account? SignUp"}
                                            </Link>
                                        </Grid> */}
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Container>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </Box>
            </div>
        </>
    );
};

export default SignIn;
